<template>
  <div class="card notSelected" @click="singleManufacturer(manufacturer.id)">
    <span class="head">{{ manufacturer.name }}</span>
    <table>
      <tr class="firmName" v-if="manufacturer.firmName!==null && manufacturer.firmName!==''">
        <td></td>
        <td>{{ manufacturer.firmName }}</td>
      </tr>
      <tr v-if="manufacturer.address!==null && manufacturer.address!==''">
        <td><i class="fa fa-map-marker"></i></td>
        <td class="info">{{ manufacturer.address }}</td>
      </tr>
      <tr v-if="manufacturer.phone!==null && manufacturer.phone!==''">
        <td><i class="fa fa-phone"></i></td>
        <td class="info">{{ manufacturer.phone }}</td>
      </tr>
      <tr v-if="manufacturer.email!==null && manufacturer.email!==''">
        <td><i class="fa fa-envelope"></i></td>
        <td class="info"> {{ manufacturer.email }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "ManufacturerCard",
  props: {
    manufacturer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selected: false
    }
  },
  methods: {
    singleManufacturer: function (id) {
      window.scrollTo(0, 0);
      this.$router.push({name: 'manufacturerById', params: {"id": id.toString()}});
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../public/styles/ManufacturerCard";
</style>