import { render, staticRenderFns } from "./ManufacturerCard.vue?vue&type=template&id=a4a8e154&scoped=true&"
import script from "./ManufacturerCard.vue?vue&type=script&lang=js&"
export * from "./ManufacturerCard.vue?vue&type=script&lang=js&"
import style0 from "./ManufacturerCard.vue?vue&type=style&index=0&id=a4a8e154&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4a8e154",
  null
  
)

export default component.exports