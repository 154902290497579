<template>
  <div>
    <div class = "hDiv">
      <Header table="manufacturers"
              v-bind:hidePanel="hidePanel"
              @search="search"
      />
    </div>
    <div id="board">
      <ManufacturerCard
          v-for="manufacturer of changedManufacturers" :key="manufacturer.id"
          v-bind:manufacturer="manufacturer"
      />
    </div>
  </div>
</template>


<script>
  import ManufacturerCard from "./ManufacturerCard";
  import Link from "../utils/Link";
  import Header from "../Header";
  import axios from "axios";
  import $ from "jquery";
  import Vue from "vue";
  import VueToast from "vue-toast-notification";

  export default {
    name: "ManufacturersBoard",

    components: {
      ManufacturerCard,
      Header
    },

    computed: {
      changedManufacturers: function () {
        return this.data.manufacturers.filter(x => this.lastQuery(x, this.isInclude))
      }
    },

    created: function () {
      window.addEventListener('scroll', this.scrolling);
    },

    destroyed() {
      window.removeEventListener('scroll', this.scrolling);
    },

    mounted() {
      window.localStorage.removeItem("savedItem");
      this.$emit("changeComponent", 'manufacturers');
    },

    data() {
      let data = {manufacturers: []}
      this.getManufacturers().then(d => {
        data.manufacturers = d
      })

      return {
        data,
        array: [],
        hidePanel: true,
        lastQuery: (x) => x
      };
    },

    methods: {
      getManufacturers() {
        const headers = Link.methods.getHeaders();
        return axios.get(
          Link.methods.getManufacturesUrl(),
          {headers}
        ).then(res => {
          return res.data.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }).catch(() => {
          this.createToast(this.$t("manufacturerBoard.manufacturersNotFound"), "error")
        });
      },

      search(qsearch) {
        this.lastQuery = qsearch;
      },

      isInclude(manufacturer, query) {
        return this.fieldContain(query, manufacturer.name)
            || this.fieldContain(query, manufacturer.firmName)
            || this.fieldContain(query, manufacturer.address)
            || this.fieldContain(query, manufacturer.phone)
            || this.fieldContain(query, manufacturer.email);
      },

      fieldContain(query, field) {
        if (field === null)
          return false;
        return field.toLowerCase().indexOf(query) !== -1;
      },

      createToast(msg, type) {
        Vue.use(VueToast);
        Vue.$toast.open({
          type: type,
          message: msg,
          position: "bottom-right",
          duration: 5000
        });
      },

      scrolling() {
        let boardHead = document.getElementById('board')
        let board = $('#board');

        let sticky = 0;

        if (board !== null)
          sticky = boardHead.offsetTop - 72;

        if (window.scrollY > sticky) {
          board.css('padding-top', '58px');
        } else {
          board.css('padding-top', '0px');
        }
      },
    }
  }
</script>


<style scoped lang="scss">
  .hDiv {
    margin-right: 18px;
  }

  #board {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
</style>