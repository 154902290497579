<template>
  <div id="header">
    <div id="searchPanel" v-show="hidePanel">
      <div class="inline">
        <input type="text" id="search" :placeholder="$t('header.search')" v-model="query" @input="sendQuery">
        <i v-if="query && query.length > 0" class="fa fa-times" style="font-size: 20px" @click="clearQuery"></i>
      </div>
      <div class="space inline"></div>

      <div class="inline addDiv">
        <button type="button" id="show-all-btn"
                class="filter"
                v-show="table === 'items'"
                v-if="showButton()"
                @click="showActiveItems()">
          <label for="show-all-btn" id="showLabel">{{ $t(this.activeItemsFilterTitle) }}</label>
        </button>
        <button type="button" id="new-btn"
                class="newButton"
                v-if="showButton()"
                @click="createSthNew()"><i class="fa fa-plus"></i>
          <label for="new-btn" class="addLabel"> {{ $t("newElementFor." + this.table) }}</label>
        </button>
      </div>
      <div class="inline addDiv" v-show="table === 'tickets'">
        <button type="button" id="filter-btn" class="filter"  @click="changeDisplayed()">
          <label for="filter-btn" class="addLabel">
            {{ $t(this.openTicketsFilterTitle) }}
          </label>
        </button>

        <button type="button" id="assigned-btn" class="filter" v-if="isRoleAdmin" @click="changeAssigned()">
          <label for="assigned-btn" class="addLabel">
            {{ $t(this.ticketAssignedFilterTitle) }}
          </label>
        </button>
      </div>
    </div>
    <div id="controlPanel" v-show="!hidePanel">
      <div class="inlinePanel">
        <label class="numOfSelected">{{ $t(selectedTitle, [counter]) }}</label>
        <div class="fa fa-clone" v-show="table === 'items'" v-if="isRoleAdmin" @click="cloneSelected">
          <span class="clonehint">{{ $t("controlPanel.cloneHint") }}</span>
        </div>
        <div class="fa fa-sign-out" v-show="table !=='users'" v-if="showExportButton()" @click="downloadFile">
          <span class="exporthint">{{ $t("controlPanel.exportHint") }}</span>
        </div>
        <div class="fa fa-trash-o" v-if="showButton()" @click="deleteAll">
          <span class="deletehint">{{ $t("controlPanel.deleteHint") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SideBarMenu from "./SideBarMenu";
import Link from "@/components/utils/Link.vue";
import axios from "axios";
import moment from "moment/moment";
import Vue from "vue";
import VueToast from "vue-toast-notification";

export default {
  components: {},
  props: {
    table: {
      type: String
    },
    hidePanel: {
      type: Boolean,
      default: true
    },
    manufacturerId: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      query: "",
      showOnlyActiveItems: true,
      activeItemsFilterTitle: "header.activeFilterTitleOn",
      showOnlyOpenTickets: true,
      openTicketsFilterTitle: "header.ticketFilterTitleOn",
      showTicketsAssignedToAnybody: false,
      ticketAssignedFilterTitle: "header.ticketAssignedToMe",
      isRoleAdmin: false,
      counter: 0,
      selectedTitle: "singleSelected.none",
    }
  },

  mounted() {
  },

  created() {
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
    this.restoreFilters();

    this.query = window.localStorage.getItem(this.getStoreKey());
    if (this.query != null) {
      this.sendQuery();
    }
  },

  methods: {
    restoreFilters() {
      let filter;
      if (this.table === 'items') {
        const storeKey = (this.manufacturerId.length > 0) ? "activeManufacturesItemsFilter" : "activeItemsFilter";
        filter = window.localStorage.getItem(storeKey);
        if (filter != null) {
          this.showOnlyActiveItems = (filter === 'true');
          this.activeItemsFilterTitle = this.showOnlyActiveItems
                                        ? "header.activeFilterTitleOn"
                                        : "header.activeFilterTitleOff";
        }
        //this.$emit("showActiveItems");
      }

      if (this.table === 'tickets') {
        filter = window.localStorage.getItem("openTicketsFilter");
        if (filter != null) {
          this.showOnlyOpenTickets = (filter === 'true');
          this.openTicketsFilterTitle = this.showOnlyOpenTickets
                                         ? "header.ticketFilterTitleOn"
                                         : "header.ticketFilterTitleOff";
        }

        filter = window.localStorage.getItem("anybodyAssignedTicketFilter");
        if (filter != null) {
          this.showTicketsAssignedToAnybody = (filter === 'true');
          this.ticketAssignedFilterTitle = (this.showTicketsAssignedToAnybody)
                                           ? "header.ticketAssignedToAnybody"
                                           : "header.ticketAssignedToMe";
        }

        //this.$emit("changeDisplayed");
      }
    },

    getStoreKey() {
      return this.table + (this.manufacturerId.length > 0 ? ".items" : "") + ".filter";
    },

    createSthNew() {
      switch (this.table) {
        case ("items"):
          if (this.manufacturerId.length > 0) {
            this.getSingleManufacturer(this.manufacturerId).then(d => {
              let manufacturer = d.data
              window.localStorage.setItem("savedManufacturer", manufacturer.name);
              return this.$router.push({name: 'addItem'});
            });
          } else {
            this.$router.push({name: 'addItem'});
          }
          break;

        case ("manufacturers"):
          this.$router.push({name: 'newManufacturer'});
          break;

        case("tickets"):
          this.$router.push({name: 'addTicket'});
          break;

        case ("users"):
          this.$router.push({name: 'user', params: { id: 'new' }} );
          break;

        case("ticketRules"):
          this.$router.push({name: 'addTicketRule'});
          break;
      }
    },

    showActiveItems() {
      this.showOnlyActiveItems = !this.showOnlyActiveItems;

      const storeKey = (this.manufacturerId.length > 0) ? "activeManufacturesItemsFilter" : "activeItemsFilter";
      window.localStorage.setItem(storeKey, this.showOnlyActiveItems)

      this.activeItemsFilterTitle = this.showOnlyActiveItems
                                    ? "header.activeFilterTitleOn"
                                    : "header.activeFilterTitleOff";

      this.$emit("showActiveItems");
    },

    showButton() {
      return (this.isRoleAdmin || this.table === 'tickets');
    },

    showExportButton() {
      return this.isRoleAdmin && this.manufacturerId == null;
    },

    getSingleManufacturer(id) {
      const headers = Link.methods.getHeaders();
      return axios.get(Link.methods.getManufacturerById(id), {headers}).then((res) => {
        res.data.created = moment(res.data.created).format('DD.MM.YYYY');
        res.data.updated = moment(res.data.updated).format('DD.MM.YYYY');
        this.manufacturer = res.data;
        return res;
      }).catch(() => {
        this.createToast(this.$t("singleManufacturer.manufacturerNotFound", [id]), "error")
      });
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },

    sendQuery() {
      const q = this.query;
      window.localStorage.setItem(this.getStoreKey(), this.query);
      this.$emit("search",
          this.query.isEmpty ? (x) => x : (x, f) => f(x, q.toLowerCase())
      );
    },

    clearQuery() {
      this.query = "";
      this.sendQuery();
    },

    changeAssigned() {
      this.showTicketsAssignedToAnybody = !this.showTicketsAssignedToAnybody;
      window.localStorage.setItem("anybodyAssignedTicketFilter", this.showTicketsAssignedToAnybody)
      this.ticketAssignedFilterTitle = (this.showTicketsAssignedToAnybody)
                                       ? "header.ticketAssignedToAnybody"
                                       : "header.ticketAssignedToMe";
      this.$emit("changeDisplayed");
    },

    changeDisplayed() {
      this.showOnlyOpenTickets = !this.showOnlyOpenTickets;
      window.localStorage.setItem("openTicketsFilter", this.showOnlyOpenTickets)
      this.openTicketsFilterTitle = (this.showOnlyOpenTickets)
                                      ? "header.ticketFilterTitleOn"
                                      : "header.ticketFilterTitleOff";
      this.$emit("changeDisplayed");
    },

    changeLabel(counter, tableName) {
      this.counter = counter;
      this.selectedTitle = ((counter === 1) ? "singleSelected." : "manySelected.") + tableName;
    },

    deleteAll() {
      this.$emit('deleteAll')
    },

    downloadFile() {
      this.$emit('download')
    },

    cloneSelected() {
      this.$emit('cloneSelected')
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../public/styles/header.scss";
@import "../../public/styles/controlPanel.scss";
@import "../../public/styles/hints.scss";

button[type="button"], button[type="button"] > label {
   cursor: pointer;
}
</style>